import React from 'react';
import Lottie from 'lottie-react';
import pinkcomp from "../assets/pinkcomp.json";
import bluecomp from "../assets/bluecomp.json";
import styled from 'styled-components';


const Container = styled.div`
    background: ${props => props.theme.color};
`

const Home = (props) => {
  return (
    <Container>
        <Lottie animationData={props.isDarkTheme ? bluecomp : pinkcomp} />
    </Container>
  );
}

export default Home;