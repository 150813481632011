import React from 'react';
import styled from 'styled-components';
import { SocialIcon } from 'react-social-icons'
import { pallete } from '../theme/theme'
import { TypeAnimation } from 'react-type-animation'

const HeaderContainer = styled.header`
    text-align: center;
`

const Icon = styled(SocialIcon)`
    margin: 10px;
    &:hover {
        cursor: pointer;
        transform: scale(1.1);
        transition: all .2s ease-in-out;
    }
`

const ToggleThemeButton = styled.button`
    display: inline-block;
    position: relative;
    vertical-align: middle;
    overflow: hidden;
    margin: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.accent};
    border: ${(props) => props.theme.accent};
    font-size: 30px;
    padding: 0;
    &:hover {
        cursor: pointer;
        transform: scale(1.1);
        transition: all .2s ease-in-out;
    }
`

const SocialMediaContainer = styled.div`
    text-align: center;
    margin: 10px;
`

const Name = styled.p`
    font-weight: bold;
    font-stlye: large;
`

const Header = (props) => {
    const iconBgColor = props.isDarkTheme ? pallete.purple : pallete.pink;
    const iconFgColor =  pallete.white;
    return (
        <HeaderContainer className='Header'>
            <Name>
                Emilie Whitesell
            </Name>
            <TypeAnimation
                sequence={[
                    'Hi 👋',
                    1000,
                    'I am a software developer',
                    1500,
                    'located in San Francisco 🌉',
                    1500,
                    'I 💖 automation, ai, and accessibility',
                    1500
                ]}
                rapper='span'
                speed={40}
                deletionSpeed={80}
                style={{ fontSize: '15px', fontFamily: 'Courier New', display: 'inline-block', margin: '5px' }}
            />
            <SocialMediaContainer>
                <Icon target='_blank' url='https://www.linkedin.com/in/emilie-whitesell-553812143/' bgColor={iconBgColor} fgColor={iconFgColor}/>
                <Icon target='_blank' url='https://github.com/emko-dev' bgColor={iconBgColor} fgColor={iconFgColor}/>
                <Icon target='_blank' url='https://open.spotify.com/user/vx134yn4jkw33fqgx4207ws6d?si=0a4e2144ac8b42a1' bgColor={iconBgColor} fgColor={iconFgColor}/>
                <ToggleThemeButton onClick={props.toggleThemeCallback}>{props.isDarkTheme ? '☀️' : '🌙'}</ToggleThemeButton>
            </SocialMediaContainer>
        </HeaderContainer>
    );
}

export default Header;