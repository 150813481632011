import { createGlobalStyle } from 'styled-components';
export const GlobalStyles = createGlobalStyle`
  html {
    font-family: "San Francisco", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  }
  body {
    display: flex;
    justify-content: center;
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    transition: background 0.2s ease-in, color 0.2s ease-in;
  }
`;
export const pallete = {
  dark: '#282A36',
  gray: '#44475A',
  light: '#F8F8F2',
  pink: '#FF79C6',
  purple: '#BD93F9'
};

export const lightTheme = {
  name: 'light',
  body: pallete.light,
  text: pallete.dark,
  accent: pallete.pink
};
export const darkTheme = {
  name: 'dark',
  body: pallete.dark,
  text: pallete.light,
  accent: pallete.purple
};