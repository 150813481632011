import React, { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme, GlobalStyles } from './theme/theme';
import Home from './pages/Home';
import styled from 'styled-components';
import Header from './components/Header';

const Body = styled.div`
  background: transparent;
  width: 100%;
  height: 100%;
`

function App() {
  const [theme, setTheme] = useState('light');
  
  const isDarkTheme = theme === 'dark';
  const toggleTheme = () => {
    const updatedTheme = isDarkTheme ? 'light' : 'dark'
    setTheme(isDarkTheme ? 'light' : 'dark')
    localStorage.setItem('theme', updatedTheme);
  };

  // grab user's default theme
  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme && ['dark', 'light'].includes(savedTheme)) {
      setTheme(savedTheme);
    }
  }, []);

  return (
    <Body className='App'>
      <ThemeProvider theme={isDarkTheme ? darkTheme : lightTheme}>
        <>
          <GlobalStyles />
          <Header isDarkTheme={isDarkTheme} toggleThemeCallback={toggleTheme}/>
          <Home isDarkTheme={isDarkTheme}/>
        </>
      </ThemeProvider>
    </Body>
  );
}

export default App;
